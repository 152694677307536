<template>
  <div class="auth-wrapper auth-v2">
    <div class="row auth-inner m-0">
      <div class="d-none d-lg-flex col-lg-7"></div>
      <div class="d-flex align-items-center auth-bg px-2 p-xl-5 col-lg-4">
        <div class="mx-auto col-sm-8 col-12 col-xl-12 p-xl-1">
          <div class="text-center mb-5 pb-2">
            <h2 class="brand-text">&nbsp;삼성화재 다이렉트<br> 자동차보험 웹 솔루션</h2>
          </div>
          <div id="incross-sso-sdk-form">
            <div id="wrap">
              <div class="auth-page">
                <form id="incross-login-form">
                  <div class="form-group">
                    <label id="incross_login_email_input_label">이메일</label>
                    <input id="incross_login_email_input" type="email" name="email" placeholder="이메일을 입력해주세요" ref="email" v-model="userEmail"/>
                  </div>
                  <div class="form-group">
                    <label id="incross_login_password_input_label">패스워드</label>
                    <input id="incross_login_password_input" type="password" name="password" placeholder="패스워드를 입력해주세요" v-model="password" @keyup.enter="goLogin" />
                  </div>
                  <input id="incross_login_btn" @click="goLogin" type="button" value="로그인" style="width: 100%; border-radius: 5px; color: #fff; font-size: 12px; height: 31px; background: #002b49; text-align: center; border: 0" />
                </form>
                <div class="checkLogin">
                  <input class="login_checkBox" type="checkbox" name="" id="login_remember"  v-model="rememberFlag" @change="setSession">
                  <label class="login_checkLabel" for="login_remember" id="login_remember"> 이메일 기억하기</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="chrome_txt">크롬 브라우저에 최적화 되어있습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { msgBoxOkOptions } from '@/utils/utils'
import { mapActions } from 'vuex'
export default {
  components: {},
  mixins: [],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      msgBoxOkOptions: msgBoxOkOptions(),
      adverType: false,
      noImgFlag: false,
      rememberFlag: false
    }
  },
  computed: {
  },
  mounted() {
    const userInfo = sessionStorage.getItem('accessToken')
    if (!userInfo) {
      sessionStorage.removeItem('userName')
    }
    const loginInfo = localStorage.getItem('loginInfo')
    if (loginInfo) {
      this.userEmail = JSON.parse(loginInfo).email
      this.rememberFlag = true
    }
  },
  methods: {
    goLogin() {
      if (this.userEmail && this.password) {
        let data = {
          user: this.userEmail,
          password: this.password
        }
        this.login(data)
        this.setSession()
      } else {
        this.$bvModal.msgBoxOk('로그인 정보를 입력해주세요', this.msgBoxOkOptions)
      }
      // this.$router.push('/searchAd/bidPrice')
    },
    setSession() {
      if (this.rememberFlag) {
        localStorage.setItem('loginInfo', JSON.stringify({email: this.userEmail}))
      } else {
        delete localStorage.loginInfo
      }
    },
    ...mapActions('common', ['login'])
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

</style>
